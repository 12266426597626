import React from 'react';
import { graphql, Link as GatsbyLink, PageRendererProps } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import classNames from 'classnames';
import Img from 'gatsby-image';
import { Parallax } from '../components/Parallax/Parallax';
import { DefaultHeader } from '../components/Header/Header';
import { ProductNode } from '../interfaces/contentful';
import { FormattedMessage, useIntl } from 'react-intl';
import { Price } from '../components/Price/Price';
import { inStockStyle } from '../assets/jss/pages/inStockStyle';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(inStockStyle);

const InStock: React.FC<InStockProps> = ({ data }) => {
  const { inStockProducts } = data;

  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.inStockPage}>
      <Helmet>
        <title>{intl.formatMessage({ id: 'breadcrumbs.inStock' })}</title>
      </Helmet>
      <DefaultHeader />

      <Parallax image={require('../assets/img/bg9.jpg')} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">
              <FormattedMessage id="breadcrumbs.inStock" defaultMessage="In Stock" />
            </Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              <FormattedMessage id="inStock.title" defaultMessage="In Stock" />
            </Typography>

            <Grid container justify="flex-start" spacing={2}>
              {inStockProducts.edges.map(({ node: product }) => {
                return (
                  <Grid item key={product.contentful_id} md={3} sm={4} xs={12}>
                    <Card product>
                      <CardHeader image plain style={{ textAlign: 'center', padding: '10px' }}>
                        <GatsbyLink to={`/products/${product.slug}`}>
                          <Img sizes={{ ...product.cover.fluid, aspectRatio: 1 / 1 }} />
                        </GatsbyLink>
                      </CardHeader>
                      <CardBody className={classes.textCenter} plain>
                        <Price product={product} />
                        <br />
                        <AddToCartButton product={product} quantity={1} size="sm" onlyIcon={true} />
                      </CardBody>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InStock;

export const inStockQuery = graphql`
  query {
    inStockProducts: allContentfulProduct(filter: { inStock: { eq: true } }) {
      edges {
        node {
          contentful_id
          slug
          title
          price
          discountedPrice
          node_locale
          inStock
          featured
          cover {
            fluid(maxWidth: 100, quality: 80) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            description
          }
        }
      }
    }
  }
`;

interface InStockProps extends PageRendererProps {
  data: {
    inStockProducts: {
      edges: [
        {
          node: ProductNode;
        },
      ];
    };
  };
}
