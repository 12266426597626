import {
  section,
  container,
  main,
  mainRaised,
  grayColor,
  breadcrumbs,
  coloredShadow,
  cardTitle,
  description,
  gridItem,
  gridContainer,
  textCenter,
} from '../materialKitBase';

import { createStyles } from '@material-ui/core/styles';

export const inStockStyle = createStyles({
  gridContainer,
  gridItem,
  description,
  textCenter,
  cardTitle,
  coloredShadow,
  breadcrumbs,
  main,
  mainRaised,
  container: {
    ...container,
  },
  section: {
    ...section,
    padding: '70px 0px',
  },
  icon: {
    marginRight: '5px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  parallax: {
    height: '70vh',
    overflow: 'hidden',
  },
  inStockPage: {
    '& $mainRaised': {
      margin: '-40vh 0 0',
      padding: '20px',
      // margin: '2vh 0 0',
      // padding: '20px',
    },
    backgroundColor: grayColor[2],
  },
  quote: {
    padding: '1px 5px',
    margin: '0 0 1px',
    fontSize: '1.25rem',
    borderLeft: '5px solid ' + grayColor[2],
  },
  quoteText: {
    margin: '0 0 10px',
    fontStyle: 'italic',
  },
});
